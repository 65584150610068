// グローバルに使いたいモジュール,関数はこちらで定義
// 残りはcomponents配下に配置してバンドルする方向性
import slick from "slick-carousel";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

$(function () {
	//
	$(document).on("click", ".header__nav__bar", function () {
		$(".header").toggleClass("is-active");
		$(".nav").toggleClass("is-active");
		$(".siteaddress").toggleClass("is-nav");
	});
	$(document).on("click", ".nav a", function () {
		var url = location.href;
		var path = location.pathname;
		var param = location.search;
		var anc = location.hash;
		if (
			$(this).attr("href") === "/#concept" ||
			$(this).attr("href") === "/#stay"
		) {
			if (path == "/") {
				$(".header").removeClass("is-active");
				$(".nav").removeClass("is-active");
			}
		}
	});

	//
	setTimeout(() => {
		$(".fadein").each(function () {
			ScrollTrigger.create({
				trigger: $(this),
				start: "center 80%",
				// markers: true,
				endTrigger: ".footer",
				end: "bottom top",
				toggleClass: "is-active",
				once: true,
			});
		});
		/*
		ScrollTrigger.create({
			trigger: ".footer",
			start: "top bottom",
			// markers: true,
			toggleClass: { targets: ".siteaddress,.pagetop", className: "is-active" },
			once: false,
		});
		*/
		if ($(".inpage__header").length) {
			ScrollTrigger.create({
				trigger: ".inpage__header",
				start: "bottom top",
				endTrigger: ".footer",
				// markers: true,
				toggleClass: { targets: ".header", className: "is-fixed" },
				once: false,
			});
		}
	}, 500);

	//
	if ($(".facilitypage").length) {
		$(".facilitypage__slides__main").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			asNavFor: ".facilitypage__slides__thumbnail",
		});
		$(".facilitypage__slides__thumbnail").slick({
			slidesToShow: 10,
			slidesToScroll: 1,
			asNavFor: ".facilitypage__slides__main",
			dots: false,
			focusOnSelect: true,
		});

		ScrollTrigger.create({
			trigger: ".facilitypage__slides__main",
			start: "top center",
			// markers: true,
			toggleClass: "is-active",
			once: true,
		});

		ScrollTrigger.create({
			trigger: ".facilitypage__introduction",
			start: "top 80%",
			toggleClass: "is-active",
			once: true,
		});
	}

	//
	if ($(".facilitypage").length) {
		setTimeout(() => {
			$(".facilitypage__header h1").addClass("is-active");
			$(".facilitypage__header h2")
				.delay(500)
				.queue(function () {
					$(this).addClass("is-active");
				});
			$(".facilitypage__header p")
				.delay(1000)
				.queue(function () {
					$(this).addClass("is-active");
				});
		}, 700);
	}
});

$(window).on("load", function () {
	const url = $(location).attr("href");
	if (url.indexOf("#") != -1) {
		// urlを#で分割して配列に格納
		const anchor = url.split("#"),
			// 分割した最後の文字列（#◯◯の部分）をtargetに代入
			target = $("#" + anchor[anchor.length - 1]),
			// リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
			position = Math.floor(target.offset().top);
		// positionの位置に移動
		$("html, body").animate({ scrollTop: position }, 0);
	}
});

/* =================================================== */
// slideUp, slideDown, slideToggle関数を定義
/* =================================================== */
// 要素をスライドしながら非表示にする関数(jQueryのslideUpと同じ)
const slideUp = (el, duration = 300) => {
	el.style.height = el.offsetHeight + "px";
	el.offsetHeight;
	el.style.transitionProperty = "height, margin, padding";
	el.style.transitionDuration = duration + "ms";
	el.style.transitionTimingFunction = "ease";
	el.style.overflow = "hidden";
	el.style.height = 0;
	el.style.paddingTop = 0;
	el.style.paddingBottom = 0;
	el.style.marginTop = 0;
	el.style.marginBottom = 0;
	setTimeout(() => {
		el.style.display = "none";
		el.style.removeProperty("height");
		el.style.removeProperty("padding-top");
		el.style.removeProperty("padding-bottom");
		el.style.removeProperty("margin-top");
		el.style.removeProperty("margin-bottom");
		el.style.removeProperty("overflow");
		el.style.removeProperty("transition-duration");
		el.style.removeProperty("transition-property");
		el.style.removeProperty("transition-timing-function");
		el.classList.remove("is-open");
	}, duration);
};

// 要素をスライドしながら表示する関数(jQueryのslideDownと同じ)
const slideDown = (el, duration = 300) => {
	el.classList.add("is-open");
	el.style.removeProperty("display");
	let display = window.getComputedStyle(el).display;
	if (display === "none") {
		display = "block";
	}
	el.style.display = display;
	let height = el.offsetHeight;
	el.style.overflow = "hidden";
	el.style.height = 0;
	el.style.paddingTop = 0;
	el.style.paddingBottom = 0;
	el.style.marginTop = 0;
	el.style.marginBottom = 0;
	el.offsetHeight;
	el.style.transitionProperty = "height, margin, padding";
	el.style.transitionDuration = duration + "ms";
	el.style.transitionTimingFunction = "ease";
	el.style.height = height + "px";
	el.style.removeProperty("padding-top");
	el.style.removeProperty("padding-bottom");
	el.style.removeProperty("margin-top");
	el.style.removeProperty("margin-bottom");
	setTimeout(() => {
		el.style.removeProperty("height");
		el.style.removeProperty("overflow");
		el.style.removeProperty("transition-duration");
		el.style.removeProperty("transition-property");
		el.style.removeProperty("transition-timing-function");
	}, duration);
};

// 要素をスライドしながら交互に表示/非表示にする関数(jQueryのslideToggleと同じ)
const slideToggle = (el, duration = 300) => {
	if (window.getComputedStyle(el).display === "none") {
		return slideDown(el, duration);
	} else {
		return slideUp(el, duration);
	}
};

/* =================================================== */
// DOM操作
/* =================================================== */

// アコーディオンを全て取得
const accordions = document.querySelectorAll(".js-accordion");
// 取得したアコーディオンをArrayに変換(IE対策)
const accordionsArr = Array.prototype.slice.call(accordions);

accordionsArr.forEach((accordion) => {
	// Triggerを全て取得
	const accordionTriggers = accordion.querySelectorAll(".js-accordion-trigger");
	// TriggerをArrayに変換(IE対策)
	const accordionTriggersArr = Array.prototype.slice.call(accordionTriggers);

	accordionTriggersArr.forEach((trigger) => {
		// Triggerにクリックイベントを付与
		trigger.addEventListener("click", () => {
			// '.is-active'クラスを付与or削除
			trigger.classList.toggle("is-active");
			// 開閉させる要素を取得
			const content = trigger.querySelector(".accordion__content");
			// 要素を展開or閉じる
			slideToggle(content);
		});
	});
});
