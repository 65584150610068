import slick from 'slick-carousel';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);
/**
	* 単純なサンプル
	* moment.jsを使って現在日付をconsole出力するだけ
	*/
$(function () {

	if ($('.loader__logo').length) {
		setTimeout(() => {
			$(".loader__logo").addClass('is-active');
		}, 500);
	}
	if ($('.toppage__slide').length) {
		$('.toppage__slide .slider').slick({
			dots: true,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
		});

		setTimeout(() => {
			ScrollTrigger.create({
				trigger: ".toppage__slide",
				start: "top center",
				toggleClass: "is-active",
				once: false,
			});
		}, 500);
	}
})
$(window).on('load', function () {
	if ($('.loader').length) {
		setTimeout(() => {
			$(".loader").addClass('is-active');
		}, 700);
	}

	if ($('.toppage__stay__list').length) {
		setTimeout(() => {
			ScrollTrigger.create({
				trigger: ".toppage__stay__list",
				start: "top 80%",
				toggleClass: "is-active",
				// markers: true,
				once: false,
			});
			ScrollTrigger.create({
				trigger: ".toppage__concept",
				start: "top top",
				endTrigger: ".footer",
				end: "bottom top",
				// markers: true,
				toggleClass: { targets: ".header", className: "is-fixed" },
				once: false,
			});
			/*
			ScrollTrigger.create({
				trigger: ".toppage__mainimage",
				start: "top center",
				end: "60% center",
				// markers: true,
				toggleClass: { targets: ".siteaddress", className: "is-active" },
				once: false,
			});
			ScrollTrigger.create({
				trigger: ".toppage__mainimage",
				start: "top center",
				end: "bottom center",
				// markers: true,
				toggleClass: { targets: ".pagetop", className: "is-none" },
				once: false,
			});
			*/
		}, 500);
	}
});
